import React from 'react';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import { Link } from 'react-router-dom';
class OpeningHours extends React.Component {
   

  render() {
    return (
        <>
           <img src='/menucartimages/opening.jpeg' style={{width:"50%"}}/>
        </>
        );
  }
}
export default OpeningHours; 